import {
  ADD_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_RESET,
  ADD_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_RESET,
  GET_USERS_SUCCESS,
  NEW_PASSWORD_USER_FAIL,
  NEW_PASSWORD_USER_REQUEST,
  NEW_PASSWORD_USER_RESET,
  NEW_PASSWORD_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESET,
  UPDATE_USER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loadingUserInfo: true }
    case USER_LOGIN_SUCCESS:
      return { loadingUserInfo: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loadingUserInfo: false, errorUserInfo: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const getUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return { loadingGetUsers: true }
    case GET_USERS_SUCCESS:
      return { loadingGetUsers: false, getUsersData: action.payload }
    case GET_USERS_FAIL:
      return { loadingGetUsers: false, errorGetUsers: action.payload }
    case GET_USERS_RESET:
      return {}
    default:
      return state
  }
}

export const addUserReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return { loadingAddUser: true }
    case ADD_USER_SUCCESS:
      return { loadingAddUser: false, addUserData: action.payload }
    case ADD_USER_FAIL:
      return { loadingAddUser: false, errorAddUser: action.payload }
    case ADD_USER_RESET:
      return {}
    default:
      return state
  }
}

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { loadingUpdateUser: true }
    case UPDATE_USER_SUCCESS:
      return { loadingUpdateUser: false, updateUserData: action.payload }
    case UPDATE_USER_FAIL:
      return { loadingUpdateUser: false, errorUpdateUser: action.payload }
    case UPDATE_USER_RESET:
      return {}
    default:
      return state
  }
}

export const newPasswordUserReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_PASSWORD_USER_REQUEST:
      return { loadingNewPasswordUser: true }
    case NEW_PASSWORD_USER_SUCCESS:
      return {
        loadingNewPasswordUser: false,
        newPasswordUserData: action.payload,
      }
    case NEW_PASSWORD_USER_FAIL:
      return {
        loadingNewPasswordUser: false,
        errorNewPasswordUser: action.payload,
      }
    case NEW_PASSWORD_USER_RESET:
      return {}
    default:
      return state
  }
}
